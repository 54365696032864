window._ = require('lodash');

try {
    window.Popper = require('@popperjs/core').default;
    window.$ = window.jQuery = require('../js/jquery');
    require('../bootstrap-3.3.4-dist/js/bootstrap');
    require('./hover-dropdown-menu');
    require('./jquery.hover-dropdown-menu-addon');
    require('./jquery.sticky');
    require('./bootstrapValidator.min');
    require('./jquery.mixitup.min');
    require('./jquery.appear');
    require('./custom');
    require('./jquery.mask.min');
    document.documentElement.classList.add(
        'jetpack-lazy-images-js-enabled'
    );
    var jetpackLazyImagesL10n = {"loading_warning": "As imagens ainda est\u00e3o sendo carregadas. Cancele a impress\u00e3o e tente novamente."};
    var wpcf7 = {"api": {"root": "https:\/\/www.ggdmetals.com.br\/wp-json\/", "namespace": "contact-form-7\/v1"}};
    var _mff = {"loader": "1"};
    /* <![CDATA[ */
    var ebd_inline = {
        "ajaxurl": "https:\/\/www.ggdmetals.com.br\/wp-admin\/admin-ajax.php",
        "ajax_nonce": "c8c9b5064e"
    };

    var nomeDoProduto = $('.title-prod').html();

    $('input[name="nameproduto"]').val(nomeDoProduto);

    $(".pessoa select").change(function () {
        var pessoa = $(".pessoa select option:selected").text();
        if (pessoa === "Pessoa Física") {
            $('.form-empresa').hide();
            $('.form-pessoa').show();
        } else if (pessoa === "Pessoa Jurídica") {

            var conteudo = '<div class="form-empresa"<div class="row"><div class="col-md-12"><div class="input-text form-group"><label>Razão Social*</label><span class="wpcf7-form-control-wrap razao"><input type="text" name="razao" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false"></span></div></div></div><div class="row"><div class="col-md-12"><div class="input-text form-group"><label>CNPJ*</label><span class="wpcf7-form-control-wrap cnpj"><input type="text" name="cnpj" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false"></span></div></div></div></div>'
            $('.form-empresa').show();
            $('.form-pessoa').hide();
        } else {
            $('.form-empresa').hide();
            $('.form-pessoa').hide();
        }
    });


    var Person = jQuery('#person');
    var Privileges = jQuery('#ag-atendimento');
    var select = this.value;
    Privileges.change(function () {
        /* Act on the event */
        if ($(this).val() == 'Telefone') {
            $('.ag-telefone').show();
            $('.ag-video').hide();
            $('#tipoVideo').val('');
        } else if ($(this).val() == 'Chamada de Vídeo') {
            $('.ag-telefone').hide();
            $('.ag-video').show();
            $('#tipoTel').val('');
        } else {
            $('.ag-telefone').hide();
            $('.ag-video').hide();
            $('#tipoVideo').val('');
            $('#tipoTel').val('');
        }
    });

    Person.change(function (event) {
        /* Act on the event */
        if ($(this).val() == 'Pessoa Física') {
            $('.ag-pf').show();
            $('.ag-pj').hide();
        } else if ($(this).val() == 'Pessoa Jurídica') {
            $('.ag-pj').show();
            $('.ag-pf').hide();
        } else {

            $('.ag-pj').hide();
            $('.ag-pf').hide();

        }
    });

    function setCookie(name, value, duration) {
        var cookie = name + "=" + escape(value) +
            ((duration) ? "; duration=" + duration.toGMTString() : "");

        document.cookie = cookie;

    }


    function getCookie(name) {

        var cookies = document.cookie;
        var prefix = name + "=";
        var begin = cookies.indexOf("; " + prefix);

        if (begin == -1) {

            begin = cookies.indexOf(prefix);

            if (begin != 0) {
                return null;
            }

        } else {
            begin += 2;
        }

        var end = cookies.indexOf(";", begin);

        if (end == -1) {
            end = cookies.length;
        }

        return unescape(cookies.substring(begin + prefix.length, end));
    }

    function deleteCookie(name) {
        if (getCookie(name)) {
            document.cookie = name + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";
        }
    }

    //console.log(getCookie("LGPD"))

    //setCookie("LGPD", "Aceito")

    $('.btn-entendi').click(function () {
        setCookie("LGPD", "Aceito")
        $('.lgpd').hide();

    })

    if (getCookie("LGPD") == "Aceito") {
        $('.lgpd').hide();
    } else {
        $('.lgpd').show();
    }
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// /**
//  * Next we will register the CSRF Token as a common header with Axios so that
//  * all outgoing HTTP requests automatically have it attached. This is just
//  * a simple convenience so we don't have to attach every token manually.
//  */
//
let token = document.head.querySelector('meta[name="csrf-token"]');
let base_url = document.head.querySelector('meta[name="base_url"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    axios.defaults.baseURL = base_url.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
